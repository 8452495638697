/**
 * Copyright ©2023 Drivepoint
 */

import React, {useEffect, useRef, useState} from "react";
import {Group, Lock, OpenInNewOutlined} from "@mui/icons-material";
import {ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {Config} from "@bainbridge-growth/node-frontend";
import usePage from "@hooks/usePage";
import useStateChange from "@hooks/useStateChange";
import Telemetry from "@services/telemetry/Telemetry";
import BainbridgeUser from "@services/user/BainbridgeUser";
import "./NavigationPageItem.less";

export type NavigationPageItemProps = {
  item: any;
  open?: boolean;
  currentPage?: any;
  onPageClick: (event: any, page: any) => void;
};

export default function NavigationPageItem(props: NavigationPageItemProps): any {

  const ref = useRef<HTMLElement>();
  const user = useStateChange<BainbridgeUser>("user");
  const page = usePage();

  const [truncated, setTruncated] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) { return; }
    if (!props.open) {
      setTruncated(false);
      return;
    }
    setTimeout(() => {
      const element: HTMLElement = ref.current?.getElementsByClassName("MuiListItemText-primary")?.[0] as HTMLElement;
      if (!element) { return; }
      setTruncated(element.offsetWidth < element.scrollWidth);
    }, 500);
  }, [ref.current, props, open]);

  function addSymbolToName(name: string) {
    if (name.toLowerCase() === "smartmodel") {
      return <>{name}<span style={{fontSize: "22px", lineHeight: 0}}>&#8482;</span></>;
    }
    return name;
  }

  function renderVisibilityIcon(): any {
    switch (props.item.visibility) {
      case "company": return <ListItemIcon className="navigation-section-page-visibility-icon"><Group /></ListItemIcon>;
      case "user": return <ListItemIcon className="navigation-section-page-visibility-icon"><Lock /></ListItemIcon>;
    }
  }

  function renderNavigationIcon(): any {
    if ((user || props.item.public) && props.item.external) {
      return <ListItemIcon className="navigation-section-page-navigation-icon"><OpenInNewOutlined /></ListItemIcon>;
    }
  }

  function renderIcon(): any {
    if (props.open === false) {
      return <Tooltip title={props.item.name} arrow placement="right">
        <div>
          <ListItemIcon className="navigation-section-page-icon">{props.item.icon && <props.item.icon />}</ListItemIcon>
        </div>
      </Tooltip>;
    }
    return <ListItemIcon className="navigation-section-page-icon">{props.item.icon && <props.item.icon />}</ListItemIcon>;
  }

  function renderName(): any {
    if (truncated) {
      return <Tooltip title={addSymbolToName(props.item.name)} arrow placement="right">
        <ListItemText className="navigation-section-page-text" primary={addSymbolToName(props.item.name)} ref={ref} />
      </Tooltip>;
    } else {
      return <ListItemText className="navigation-section-page-text" primary={addSymbolToName(props.item.name)} ref={ref} />;
    }
  }

  const classes = ["navigation-section-page"];
  if (props.item.id === page.current?.id) { classes.push("highlight"); }

  return <ListItemButton
    className={`${classes.join(" ")}`}
    onClick={(event: any) => {
      if (props.item.name.toLowerCase() === "reports") {Telemetry.track("clickreportmenu");}
      if (props.item.name.toLowerCase() === "smartmodel" && Config.get("environment") !== "production") {
        window.open("https://app-staging.drivepoint.io/");
        return;
      }
      props.onPageClick(event, props.item);
    }}
  >
    {renderIcon()}
    {renderVisibilityIcon()}
    {renderName()}
    {renderNavigationIcon()}
  </ListItemButton>;

}
